import React from "react";
import { api } from "src/api";
import { LinkActionInviteToLoan } from "src/backend";
import { RoleType } from "src/constants/loan";
import { getPendingInvites, selectPendingInvites } from "src/slices/user";
import { useDispatch, useSelector } from "src/store";
import { toast } from "src/utils/toast";
import { loanMock, primaryLenderUserMock, userMock } from "test-utils/objects-mocks";

interface PendingInvitesHookState {
    pendingInvites: LinkActionInviteToLoan[];
    refresh: () => void;
    onAccept: (invite: LinkActionInviteToLoan) => void;
    onAcceptAll: () => void;
    loading: boolean;
}

export const usePendingInvites = (): PendingInvitesHookState => {
    const [loading, setLoading] = React.useState(false);
    const dispatch = useDispatch();
    const pendingInvites = useSelector(selectPendingInvites);

    React.useEffect(() => {
        dispatch(getPendingInvites())
    }, [dispatch]);

    const handleRefreshPendingInvites = React.useCallback(() => {
        dispatch(getPendingInvites())
    }, [dispatch]);

    const handleAcceptInvite = React.useCallback(async (invite: LinkActionInviteToLoan) => {
        await api.doAction(invite.id);
        toast({
            content: "Invite accepted",
            type: "success"
        })
        // refresh pending invites
        handleRefreshPendingInvites();
    }, [handleRefreshPendingInvites]);

    const handleAcceptAll = React.useCallback(async () => {
        setLoading(true);
        await Promise.all(pendingInvites.map(invite => api.doAction(invite.id)));
        // refresh pending invites
        handleRefreshPendingInvites();
        setLoading(false);
        toast({
            content: "All invites accepted",
            type: "success"
        })
    }, [handleRefreshPendingInvites, pendingInvites]);

    return {
        pendingInvites,
        loading,
        onAcceptAll: handleAcceptAll,
        onAccept: handleAcceptInvite,
        refresh: handleRefreshPendingInvites
    }
}
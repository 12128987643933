import Autocomplete from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import { quickRepliesSelector } from 'src/slices/chat';
import { uiSelector } from 'src/slices/ui';
import { useSelector } from 'src/store';

const PopperMy = (props) => {
    return (<Popper {...props} placement='top-start' style={{ zIndex: 9999, width: 300 }} />)
}

interface Props {
    onChange: (event) => void;
    value: string;
    disabled?: boolean;
}

const ChatMessageSubjectAutocomplete = ({ onChange, value, disabled }: Props) => {
    const replies = useSelector(quickRepliesSelector);

    const { previewMode } = useSelector(uiSelector);
    return (
        <Autocomplete
            PopperComponent={PopperMy}
            id="chat-message-subject-autocomplete"
            freeSolo
            autoSelect
            value={value}
            disableClearable={!previewMode}
            options={previewMode ? replies : []}
            onInputChange={(_, newInputValue) => {
                onChange(newInputValue);
            }}
            sx={{
                width: '100%',
            }}
            renderInput={(params) => <TextField {...params}
                disabled={disabled}
                fullWidth
                inputProps={{
                    "data-testid": "chat-message-subject-autocomplete-input",
                    ...params.inputProps,
                }}
                placeholder="Add a subject"
                name="messageSubject"
                size="small"
                variant="outlined"
                sx={{ mb: 1 }} />}
        />
    );
};

ChatMessageSubjectAutocomplete.displayName = 'ChatMessageSubjectAutocomplete';

export default ChatMessageSubjectAutocomplete;
import { KeyStorage } from "src/constants/ui";

import { useKeyStorage } from "./use-key-storage";

export const useUserTimezoneMode = () => {
    const {
        value: remoteSettings,
        setValue: saveRemoteSettings,
        isLoading: isLoadingRemoteSettings } = useKeyStorage<{
            mode: 'auto' | 'ask' | 'manual',
        }>(KeyStorage.TimezoneMode, {
            mode: 'ask',
        });

    return {
        mode: remoteSettings.mode,
        loading: isLoadingRemoteSettings,
        onChange: saveRemoteSettings,
    }
};
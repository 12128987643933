import { Search } from '@mui/icons-material';
import { Theme, useMediaQuery } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import debounce from 'lodash.debounce';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import {
    doSearch,
    moreResultsEnabledSelector,
    resetSearch,
    searchLoadingSelector,
    searchResultSelector,
    setSearchDevice
} from 'src/slices/search';
import { useDispatch, useSelector } from 'src/store';
import { SearchItem } from 'src/types/search';

import { SearchResultRenderOption } from './search-result-render-option';


let debouncedSearch = null;

export const SearchForm = () => {
    const [isFocused, setIsFocused] = useState(false);
    const [query, setQuery] = useState('');
    const dispatch = useDispatch();
    const router = useRouter();
    const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    // redux state selector
    const options: SearchItem[] = useSelector(searchResultSelector(1));
    const loading = useSelector(searchLoadingSelector);

    const goToSearchPage = useSelector(moreResultsEnabledSelector);

    // component life cycle
    useEffect(() => {
        dispatch(setSearchDevice(smDown ? 'mobile' : 'desktop'));
    }, [dispatch, smDown]);

    useEffect(() => {
        setIsFocused(false);
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [router.isReady, router.asPath]
    );
    // user actions handlers
    const handleChange = (_: any, value: any) => {
        setQuery(value);
        debouncedSearch?.cancel();
        debouncedSearch = debounce((debouncedValue: string) => {
            if (`${debouncedValue}`.length > 1) {
                dispatch(doSearch(debouncedValue));
            } else {
                dispatch(resetSearch())
            }
        }, 750);
        debouncedSearch(value);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const showBackDrop = isFocused && smDown

    return (
        <>
            <IconButton
                onClick={handleFocus}
                data-testid='toggle-search-form'
                sx={{
                    color: 'white',
                    display: smDown ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <Search />
            </IconButton>
            <Backdrop
                onClick={handleBlur}
                open={showBackDrop}
                sx={{ zIndex: (theme: Theme) => theme.zIndex.appBar }} />
            <Stack spacing={2}
                sx={{
                    display: {
                        xs: isFocused ? 'flex' : 'none',
                        sm: 'flex'
                    },
                    width: 400,
                    minWidth: {
                        xs: 400,
                        sm: 150,
                        md: 200,
                        lg: 300,
                        xl: 400
                    },
                    transition: 'width 150ms ease-in-out',
                    ...(showBackDrop && {
                        position: 'fixed',
                        left: 0,
                        top: 0,
                        right: 0,
                        mt: 2,
                        px: 1,
                        zIndex: (theme: Theme) => theme.zIndex.appBar
                    })
                }}>
                <Autocomplete
                    open={isFocused}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    loading={loading}
                    disableClearable
                    clearIcon={null}
                    blurOnSelect
                    loadingText="Searching please wait..."
                    sx={{
                        '.MuiOutlinedInput-root': {
                            backgroundColor: 'background.paper',
                        },
                        '.MuiInputLabel-root': {
                            color: 'primary.light',
                        },
                        display: 'inline-block',
                        '& input': {
                            width: 200,
                            backgroundColor: 'background.paper',
                            color: (theme) =>
                                theme.palette.getContrastText(theme.palette.background.paper),
                        }
                    }}
                    onChange={handleBlur}
                    onInputChange={handleChange}
                    id="navbar-search"
                    options={options}
                    noOptionsText="No results"
                    filterOptions={x => x}
                    getOptionLabel={(option) => option.header}
                    renderOption={(props, option, state) => SearchResultRenderOption(props, option, state,
                        {
                            query,
                            onBlur: handleBlur,
                            goToSearchPage,
                            options
                        }
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Search..."
                            size='small'
                        />
                    )}
                />
            </Stack>
        </>
    );
}



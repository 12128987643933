import { Role } from "src/backend";
import { UserType } from "src/constants/user";
import { contextLoanSelector } from "src/slices/loan"
import { useSelector } from "src/store"

export const useUserType = (id: string) => {
    const loan = useSelector(contextLoanSelector);

    if (loan?.loanRoles.some(role => role.user?.id === id && [Role.BORROWER, Role.LEAD_BORROWER].includes(role.role))) {
        return UserType.APPLICANT;
    } else if (loan?.loanRoles.some(role => role?.user.id === id && [
        Role.LEAD_LENDER,
        Role.LENDER,
        Role.ASSOCIATE_LENDER,
        Role.UNDERWRITER_LENDER,
        Role.MANAGER_LENDER].includes(role.role))) {
        return UserType.LENDER;
    }

    return UserType.ANONYMOUS;
}
import Delete from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import SendTimeExtension from '@mui/icons-material/SendTimeExtension';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { type Theme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useRouter } from 'next/router';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { LoanRoleDto } from 'src/backend';
import { QuillEditor } from 'src/components/quill-editor';
import { UserAutocomplete } from 'src/components/user/user-autocomplete';
import { LENDER_VIEW, SUPPORT_USERNAME } from 'src/constants/person';
import useIsomorphicLayoutEffect from 'src/hooks/use-isomorphic-layout-effect';
import { useSendDigest } from "src/hooks/use-send-digest";
import { draftMessageSelector, getSubjectQuickReplies, replyToMessageSelector, setDraftMessage, setReplyToMessage } from 'src/slices/chat';
import { contextLoanSelector } from 'src/slices/loan';
import { userSelector, viewTypeSelector } from 'src/slices/view';
import { useDispatch, useSelector } from 'src/store';
import { Message } from 'src/types/chat';
import { Loan } from 'src/types/loan';
import { htmlToText } from 'src/utils/html-to-text';
import { isIdALenderOnLoanRole } from 'src/utils/loan/is-id-lender-on-loan-role';
import { quillDecodeIndent } from 'src/utils/quill-fix-indent';
import { getUserDisplayName } from 'src/utils/user/get-user-display-name';

import { LOAN_TABS } from '../tabs/loan-tabs-nav';
import { ChatChangeFormElementAssigneeDialog } from './chat-change-form-element-assignee-dialog';
import ChatMessageSubjectAutocomplete from './chat-message-subject-autocomplete';

interface ChatMessageAddProps {
    disabled?: boolean;
    roles?: LoanRoleDto[];
    toUserNames?: string[];
    selectedContacts?: string[];
    onClose?: () => void;
    defaultValues?: {
        subject?: string;
        body?: string;
    },
    onRecipientsOpen?: () => void;
    onSend?: ({ subject, body, ids, replyToId, receiverId, senderId, contextId, receiverName }: { subject: string, body: string, ids: string[], replyToId?: string, receiverId: string, senderId: string, receiverName: string, contextId?: string }) => void;
}

export const ChatMessageAdd = forwardRef<any, ChatMessageAddProps>((props, ref) => {
    const messageBodyReference = useRef<HTMLInputElement>(null);
    const [recipientAssigneeId, setRecipientAssigneeId] = useState<string>();
    const loan: Loan = useSelector(contextLoanSelector);
    const lendersIds = useMemo(() => loan?.loanRoles?.filter(loanRole => isIdALenderOnLoanRole(loanRole.user.id, loanRole)).map(loanRole => loanRole.user.id) ?? [], [loan]);
    const dispatch = useDispatch();
    const { query: { tab: queryTab, formElementId, loanId } } = useRouter();
    // props
    const { disabled = false, onSend, onClose, roles = [], selectedContacts = [], defaultValues, toUserNames = [] } = props;
    // component state
    const [recipient, setRecipient] = useState<string>();
    const [body, setBody] = useState<string>(defaultValues?.body ?? '');
    const [subject, setSubject] = useState<string>(defaultValues?.subject ?? '');
    // redux state
    const replyToMessage = useSelector(replyToMessageSelector);
    const draftMessage = useSelector(draftMessageSelector);
    const user = useSelector(userSelector);
    const viewType = useSelector(viewTypeSelector);
    const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'), {
        noSsr: true
    });
    const { composeDigest } = useSendDigest({ loanId: String(loanId), userId: recipient });
    const isUserALender = viewType === LENDER_VIEW;
    const canSend = useMemo(() => (!!htmlToText(body) || !!subject) && (!!recipient || toUserNames?.length), [body, recipient, subject, toUserNames?.length]);
    const isBodyFocused = (toUserNames?.length > 0 && toUserNames?.includes(SUPPORT_USERNAME));
    // component life-cycle methods

    useImperativeHandle(ref, () => ({
        focus: () => {
            messageBodyReference.current?.focus();
        },
        clear: () => {
            handleCancel();
        }
    }));

    useEffect(() => {
        if (!!replyToMessage) {
            setRecipient(replyToMessage.senderId)
            setSubject(replyToMessage.subject);
            setBody(replyToMessage.body);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [replyToMessage]);

    useEffect(() => {
        if (!!draftMessage && !replyToMessage) {
            setRecipient(draftMessage.receiverId)
            setSubject(draftMessage.subject);
            setBody(draftMessage.body);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useIsomorphicLayoutEffect(() => {
        if (selectedContacts?.length > 0) {
            setRecipient(selectedContacts?.[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedContacts.length]);

    useEffect(() => {
        if (!!replyToMessage?.body) {
            setTimeout(() => {
                messageBodyReference.current?.focus();
            }, 500)
            dispatch(getSubjectQuickReplies({
                context: replyToMessage.context,
                contextPayload: replyToMessage.contextPayload
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [replyToMessage]);

    const handleClearRecipient = () => {
        setRecipient(null);
        if (replyToMessage) {
            dispatch(getSubjectQuickReplies({
                context: queryTab === LOAN_TABS.PACKAGE ? 'SECTION_ELEMENT' : 'LOAN',
                contextPayload: replyToMessage.id
            }));
        }
    }

    const clearCompose = () => {
        handleClearRecipient();
        setBody('');
        setSubject('');
        dispatch(setReplyToMessage(null));
        if (queryTab === LOAN_TABS.PACKAGE) {
            dispatch(getSubjectQuickReplies({
                context: 'SECTION_ELEMENT',
                contextPayload: String(formElementId)
            }));
        } else {
            dispatch(getSubjectQuickReplies({
                context: 'LOAN',
                contextPayload: String(loanId)
            }));
        }
        if (xsDown) {
            onClose?.();
        }
    }
    // user actions methods
    const handleRecipientsChange = useCallback((id: string) => {
        setRecipient(id);
        if (!!draftMessage?.contextPayload?.assignedToUserId &&
            id !== draftMessage?.contextPayload?.assignedToUserId &&
            !lendersIds.includes(id) &&
            isUserALender) {
            setRecipientAssigneeId(id)
        }
    }, [draftMessage?.contextPayload?.assignedToUserId, isUserALender, lendersIds])

    const handleSubjectChange = (newValue: string): void => {
        setSubject(newValue);
    };

    const handleSend = (): void => {
        if (!canSend) {
            return;
        }
        const recipientFullName = roles.filter(({ user }) => recipient === user.id).map(({ user }) => getUserDisplayName(user)).join(", ")
        onSend?.({
            subject,
            body: quillDecodeIndent(body),
            ids: [recipient],
            replyToId: replyToMessage?.id,
            receiverId: recipient,
            senderId: user.id,
            receiverName: recipientFullName,
            contextId: replyToMessage?.contextId ?? draftMessage?.contextId,
        });
        clearCompose();
    };

    const handleBodyChange = (newValue: string): void => {
        setBody(newValue);
        dispatch(setDraftMessage({
            id: null,
            subject,
            receiverId: recipient,
            body: newValue,
            ...replyToMessage
        } as Message));
    }

    const handleCancel = (): void => {
        clearCompose();
        if (onClose) {
            onClose();
        }
    }

    const handleChangeFormElementAssigneeDialogClose = () => {
        setRecipientAssigneeId(null);
    }

    return (
        <Box
            sx={{
                backgroundColor: 'background.paper',
                overflow: 'hidden',
                display: 'flex',
                borderTop: 'solid #dfdfdf 1px',
                flexShrink: 0,
                px: 2,
                width: '100%',
                height: '100%',
                py: 1,
                flex: 1
            }}>

            <Box sx={{ overflow: 'hidden', display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pb: 1 }}>
                    <Box sx={{ flex: 1 }} />
                    <Box>
                        {!toUserNames?.length && <Tooltip title="Cancel" arrow placement='top' disableInteractive>
                            <span>
                                <IconButton
                                    color="error"
                                    onClick={handleCancel}
                                >
                                    <Delete fontSize="small" />
                                </IconButton>
                            </span>
                        </Tooltip>}
                        {isUserALender && <Tooltip title={`Send Digest`} arrow placement='top' disableInteractive>
                            <span>
                                <IconButton
                                    id={`form-element-digest-send`}
                                    aria-controls='form-element-digest-menu-send'
                                    aria-haspopup="true"
                                    aria-expanded='true'
                                    color='primary'
                                    disabled={!recipient}
                                    onClick={composeDigest}
                                    sx={{
                                        pointerEvents: 'auto',
                                        px: .5,
                                        alignItems: 'center'
                                    }} className='ActionButton'>
                                    <SendTimeExtension />
                                </IconButton>
                            </span>
                        </Tooltip>
                        }
                        <Tooltip title="Send" arrow placement='top' disableInteractive>
                            <span>
                                <IconButton
                                    color="success"
                                    data-testid='send-btn'
                                    disabled={!canSend || disabled}
                                    onClick={handleSend}>
                                    <SendIcon fontSize="small" />
                                </IconButton>
                            </span>
                        </Tooltip>

                    </Box>
                </Box>
                {toUserNames?.length === 0 && <UserAutocomplete
                    loanId={String(loanId)}
                    onChange={handleRecipientsChange}
                    defaultValue={recipient} />}
                <ChatMessageSubjectAutocomplete
                    value={subject}
                    onChange={handleSubjectChange}
                    disabled={disabled} />
                <Box sx={{ flex: 1, overflow: 'hidden' }} data-testid="quill-chat" data-text-editor="quill-chat">
                    <QuillEditor
                        onChange={handleBodyChange}
                        bounds={`[data-text-editor="quill-chat"]`}
                        autoFocus={isBodyFocused}
                        disabled={disabled}
                        forwardedRef={messageBodyReference}
                        placeholder="Write your message here..."
                        sx={{
                            height: '100%',
                            '& .ql-container': {
                                height: '100%',
                                overflow: 'hidden',
                            },
                            '& .ql-editor': {
                                height: '100%',
                                overflowY: 'scroll !important'
                            }
                        }}
                        value={body}
                    />
                </Box>
            </Box>
            <ChatChangeFormElementAssigneeDialog
                onClose={handleChangeFormElementAssigneeDialogClose}
                open={!!recipientAssigneeId && !!draftMessage?.contextPayload?.formElementId}
                userId={recipientAssigneeId}
                formElementId={draftMessage?.contextPayload?.formElementId}
                loanId={String(loanId)} />
        </Box>
    );
});

ChatMessageAdd.displayName = 'ChatMessageAdd';


export default ChatMessageAdd;
import AppBar, { type AppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Icon from 'components/dist/atoms/Icon';
import NextLink from 'next/link';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import { useRef, useState } from 'react';
import { Permission } from 'src/constants/user';
import { useUser } from 'src/hooks/use-user';
import { getInitials } from 'src/utils/get-initials';
import { getUserDisplayName } from 'src/utils/user/get-user-display-name';
import { UrlObject as Url } from 'url';

import { Menu as MenuIcon } from '../../icons/menu';
import { SearchForm } from '../search/search-form';
import { LoanRoleGuard } from '../user/loan-role-guard';
import { UserAvatar } from '../user/user-avatar';
import { UserRoleGuard } from '../user/user-role-guard';
import { AccountPopover } from './account-popover';
import { Notifications } from './notifications';

interface DashboardNavbarProps extends AppBarProps {
  onOpenSidebar?: () => void;
  sidebarOpen?: boolean;
  backToLink?: string | Url;
  noSearch?: boolean;
  noSideBar?: boolean;
}

const DashboardNavbarRoot = styled(AppBar)(({
  backgroundColor: 'white',
  color: 'black',
  boxShadow: 'none'
}));

const AccountButton = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const { user, userRole } = useUser();
  const openPopoverWithSecretMenu = (event) => {
    event.preventDefault();
    setOpenPopover(true);
  }

  const handleOpenPopover = (event): void => {
    openPopoverWithSecretMenu(event);
  };

  const handleContextMenu = (event): void => {
    openPopoverWithSecretMenu(event);
  };

  const handleClosePopover = (): void => {
    setOpenPopover(false);
  };

  return (
    <>
      <UserRoleGuard permission={Permission.SeePendingInvites}>
        <Notifications />
      </UserRoleGuard>
      <Box
        component={ButtonBase}
        onContextMenu={handleContextMenu}
        onClick={handleOpenPopover}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: 1,
          ml: {
            xs: 1,
            sm: 2,
          }
        }}
        data-testid='account-popover-trigger'
        id={'account-button' /* for selenium automation */}
      >
        <UserAvatar
          userId={user.id}
          role={userRole}
          sx={{
            backgroundColor: 'primary.main',
            height: 32,
            width: 32
          }}
        >
          {getInitials(getUserDisplayName(user))}
        </UserAvatar>
      </Box>
      <AccountPopover
        anchorEl={anchorRef.current}
        onClose={handleClosePopover}
        open={openPopover}
      />
    </>
  );
};


export const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onOpenSidebar, backToLink, noSearch, ...other } = props;
  return (
    <>
      <DashboardNavbarRoot
        className="border-b border-gray-200"
        sx={(theme) => ({
          left: {
            lg: props.noSideBar ? '0px' : '200px',
          },
          width: {
            lg: props.noSideBar ? '100%' : 'calc(100% - 200px)',
          },
          transition: theme.transitions.create(['left', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
          }),
          ...(!props.sidebarOpen && {
            width: {
              lg: 'calc(100% - 56px)',
            },
            left: {
              lg: "56px"
            },
            transition: theme.transitions.create(['left', 'width'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen
            })
          })
        })}
        {...other}
      >
        <Toolbar
          variant='dense'
          disableGutters
          sx={{
            minHeight: 54,
            height: 54,
            left: 0,
            px: {
              xs: 1,
              md: 1
            }
          }}
        >
          {typeof onOpenSidebar !== 'undefined' && <IconButton
            data-testid='sidebar-toggle-trigger'
            onClick={onOpenSidebar}>
            <MenuIcon fontSize="small" />
          </IconButton>}
          {backToLink &&
            <NextLink href={backToLink} passHref>
              <a>
                <Icon name="NavArrowLeft" width={20} height={20} />
              </a>
            </NextLink>}
          <div className='w-full' />
          {!noSearch && <LoanRoleGuard
            permission={Permission.Search}>
            <SearchForm />
          </LoanRoleGuard>}
          <AccountButton />
        </Toolbar>
      </DashboardNavbarRoot>
    </>
  );
};

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

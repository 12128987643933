import * as React from "react"

export const Upload = (props) => (
  <svg
    width={18}
    height={20}
    fill="none"
    data-testid="Upload"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 20"
    {...props}
  >
    <path
      d="M17 11v6a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-6m8 2V1m0 0L5.5 4.5M9 1l3.5 3.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
import 'react-quill/dist/quill.snow.css';

import { styled } from '@mui/material/styles';
import dynamic from 'next/dynamic';
import { useEffect } from 'react';

export const Quill = dynamic(
    async () => {
        const { default: RQ } = await import("react-quill");
        // @ts-ignore
        // eslint-disable-next-line react/display-name
        return ({ forwardedRef, ...props }) => <RQ modules={{ clipboard: { matchVisual: false } }} ref={forwardedRef} {...props} />;
    },
    {
        ssr: false
    }
);

const QuillEditorRoot = styled(Quill)(
    ({ theme }) => ({
        border: 1,
        borderColor: theme.palette.divider,
        width: '100%',
        borderRadius: theme.shape.borderRadius,
        borderStyle: 'solid',
        display: 'flex',
        flexDirection: 'column',
        '& .ql-snow .ql-editor a': {
            textDecoration: 'none',
        },
        '& .ql-snow.ql-toolbar': {
            borderColor: theme.palette.divider,
            borderLeft: 'none',
            borderRight: 'none',
            borderTop: 'none',
            '& .ql-picker-label': {
                paddingLeft: 0,
            },
            '& .ql-picker-label:hover': {
                color: theme.palette.primary.main,

            },
            '& .ql-picker-label.ql-active': {
                color: theme.palette.primary.main
            },
            '& .ql-picker-item:hover': {
                color: theme.palette.primary.main
            },
            '& .ql-picker-item.ql-selected': {
                color: theme.palette.primary.main
            },
            '& button': {
                padding: 3,
                width: 26
            },
            '& button:hover': {
                color: theme.palette.primary.main,
                '& .ql-stroke': {
                    stroke: theme.palette.primary.main
                }
            },
            '& button:focus': {
                color: theme.palette.primary.main,
                '& .ql-stroke': {
                    stroke: theme.palette.primary.main
                }
            },
            '& button.ql-active': {
                '& .ql-stroke': {
                    stroke: theme.palette.primary.main
                }
            },
            '& .ql-stroke': {
                stroke: theme.palette.text.primary
            },
            '& .ql-picker': {
                color: theme.palette.text.primary
            },
            '& .ql-formats': {
                marginRight: 5,
            },
            '& .ql-picker-options': {
                backgroundColor: theme.palette.background.paper,
                border: 'none',
                borderRadius: theme.shape.borderRadius,
                boxShadow: theme.shadows[10],
                padding: theme.spacing(2)
            }
        },
        '& .ql-snow.ql-container': {
            borderWidth: 0,
            flexGrow: 1,
            '& .ql-editor': {
                color: theme.palette.text.primary,
                fontFamily: theme.typography.body1.fontFamily,
                fontSize: theme.typography.body1.fontSize,
                padding: theme.spacing(2),
                '&a': {
                    textDecoration: 'none',
                },
                '&.ql-blank::before': {
                    color: theme.palette.text.secondary,
                    fontStyle: 'normal',
                    left: theme.spacing(2)
                }
            }
        }
    })
);

export const QuillEditor = (props) => {
    useEffect(() => {
        const tabHideEls = document.querySelectorAll(".ql-toolbar .ql-formats button, .ql-toolbar .ql-formats .ql-picker-label");
        tabHideEls.forEach(function (item) { item.setAttribute("tabindex", "-1"); });
    });
    return (
        <QuillEditorRoot {...props} />
    )
};

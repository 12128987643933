import Dialog from '@mui/material/Dialog';
import { type Breakpoint, SxProps } from '@mui/material/styles';
import { PropsWithChildren } from 'react';
import { themeV2 } from 'src/theme/mysherpas-theme-option';

type Props = PropsWithChildren<{
    open: boolean;
    onClose: () => void;
    maxWidth?: Breakpoint;
    fullScreen?: boolean;
    hideBackdrop?: boolean;
    blackBackdrop?: boolean;
    sx?: SxProps;
    PaperSx?: SxProps;
    disablePortal?: boolean;
}>;

export const DialogContainer = (props: Props): JSX.Element => {

    return <Dialog
        fullScreen={props.fullScreen}
        fullWidth
        hideBackdrop={props.hideBackdrop}
        disablePortal={props.disablePortal}
        disableEnforceFocus={props.disablePortal}
        sx={{
            ...props.sx,
            ...props.disablePortal && {
                position: 'absolute',
                inset: 0,
            }
        }}
        BackdropProps={{
            sx: {
                ...props.disablePortal && {
                    position: 'absolute',
                },
                ...props.blackBackdrop && {
                    bgcolor: themeV2.colors.black.neutral,
                }
            }
        }}
        PaperProps={{
            sx: {
                ...props.PaperSx,
                ...props.hideBackdrop && {
                    boxShadow: '0',
                },
                ...props.disablePortal && {
                    position: 'absolute',
                }
            }
        }}
        maxWidth={props.maxWidth ?? "sm"}
        open={props.open}

        onClose={props.onClose}
        aria-labelledby="mysherpas-generic-dialog"
        aria-describedby="mysherpas-generic-dialog">
        {props.children}
    </Dialog>
}
import sanitizeHtml from 'sanitize-html';

export const htmlToText = (html: string): string => {
    const sanitizedText = sanitizeHtml(html, {
        allowedTags: [],
        allowedAttributes: {},
    });
    const text = sanitizedText.replace(/&nbsp;/g, ' ');
    return text;
};

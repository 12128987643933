import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContainer } from 'src/components/common/dialog-container';
import { ZipFileAction } from 'src/constants/common';
import { updateFormElements } from 'src/slices/form-element';
import { useDispatch } from 'src/store';

interface ChatChangeFormElementAssigneeDialogProps {
    open: boolean;
    onClose: () => void;
    formElementId: string;
    userId: string;
    loanId: string;
}

export const ChatChangeFormElementAssigneeDialog = (props: ChatChangeFormElementAssigneeDialogProps) => {
    const dispatch = useDispatch();

    const handleConfirmClick = () => {
        dispatch(updateFormElements({
            multiSelect: false,
            formElements: [{
                id: props.formElementId,
                assignedToUserId: props.userId,
                loanId: props.loanId
            }],
            loanId: props.loanId
        }));
        props.onClose();
    }

    return <DialogContainer open={props.open} onClose={props.onClose}>
        <DialogTitle sx={{ textAlign: 'center', pt: 5 }}>Recipient does not match Assignee</DialogTitle>
        <DialogContent sx={{ textAlign: 'center', px: 8 }}>
            You are trying to send a message to a recipient that does not match the Assignee of this file request. Do you want to change the Assignee to the recipient?
        </DialogContent>
        <DialogContent sx={{ alignItems: 'center', justifyContent: 'center', gap: 4, pb: 5, display: 'flex' }}>
            <Button
                variant='contained'
                size='large'
                color='secondary'
                sx={{ minWidth: 160, }}
                value={ZipFileAction.IGNORE}
                onClick={props.onClose}>
                No
            </Button>
            <Button
                variant='contained'
                size='large'
                color='primary'
                sx={{ minWidth: 160, }}
                value={ZipFileAction.EXTRACT}
                onClick={handleConfirmClick}>
                Yes
            </Button>
        </DialogContent>

    </DialogContainer>
}
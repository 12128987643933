export const JestTestId = {
    LoanOverviewHederContainer: 'loan-overview-header-container',
    TangoIframe: "support-guide",
    LoanOverviewProgressContainer: 'loan-overview-progress-container',
    LoanOverviewApplicantContainer: 'loan-overview-applicant-container',
    LoanOverviewPhaseMenuContainer: 'loan-overview-phase-menu-container',
    LoanOverviewCollateralContainer: 'loan-overview-collateral-container',
    LoanOverviewTeamContainer: 'loan-overview-team-container',
    LoanOverviewHederSummaryButton: 'loan-overview-header-summary-button',
    LoanOverviewSummaryDialog: 'loan-overview-summary-dialog',
    TruckIcon: 'TruckIcon',
    LotOfCashIcon: 'LotOfCashIcon',
    LoanOverviewSummaryDialogBackDrop: 'loan-overview-summary-dialog-backdrop',
    LoanOverviewSummaryDialogHeader: 'loan-overview-summary-dialog-header',
    LoanOverviewSummaryDialogProgress: 'loan-overview-summary-dialog-progress',
    LoanOverviewSummaryDialogTeam: 'loan-overview-summary-dialog-team',
    OfficeAppsIframe: 'office-apps-iframe',
    LoanOverviewSummaryDialogDocuments: 'loan-overview-summary-dialog-documents',
    LoanOverviewSummaryDialogTeamList: 'loan-overview-summary-dialog-team-list',
    LoanOverviewApplicantEditButton: 'loan-overview-applicant-edit-button',
    LoanOverviewCollateralAddButton: 'loan-overview-collateral-add-button',
    AuthLogoImage: 'auth-logo-image',
    LoanOverviewCollateralToggleCollateralButton: 'loan-overview-collateral-toggle-collateral-button',
    LoanOverviewCollateralChip: 'loan-overview-collateral-chip',
    LoanOverviewTeamLendingTeamTab: 'loan-overview-team-lending-team-tab',
    LoanOverviewTeamApplicantsTab: 'loan-overview-team-applicants-tab',
    LoanOverviewTeamAddButton: 'loan-overview-team-add-button',
    LoanOverviewPhaseMenuButton: 'loan-overview-phase-menu-button',
    SpreadSheetViewerError: 'spread-sheet-viewer-error',
    UploadProgressContainer: 'upload-progress-container',
    FileDropTarget: 'form-element-dropzone',
    LoanOverviewPhaseMenuCloseButton: 'loan-overview-phase-menu-close-button',
    LabelChipContainer: 'label-chip-container',
    LoanOverviewPersonTooltipCardDeleteButton: 'loan-overview-person-tooltip-card-delete-button',
    LoanOverviewPersonTooltipCardEditButton: 'loan-overview-person-tooltip-card-edit-button',
    LoanOverviewPersonTooltipCardSendMessageButton: 'loan-overview-person-tooltip-card-send-message-button',
    LoanOverviewTeamMemberFormComponentRemoveLender: 'loan-overview-team-member-form-component-remove-lender',
    LoanOverviewRoleCardProgress: 'loan-overview-role-card-progress',
    LoanOverviewRoleCardProgressTooltip: 'loan-overview-role-card-progress-tooltip',
    InvitesBannerViewAllButton: 'invites-banner-view-all-button',
    NotificationsPendingInvitesCloseButton: 'notifications-pending-invites-close-button',
    SpreadSheetViewer: 'spread-sheet-viewer',
    EntityEditButton: 'entity-edit-button',
    AddressInputFields: {
        StateProvinceRegion: 'address-input-fields-state-province-region',
    },
    LoanTypeComboBox: 'loan-type-combo-box',
    ChatMessageSubjectInput: 'chat-message-subject-autocomplete-input',
    ChatMessageReplyReceiverButton: 'reply-receiver-button',
    LoanListViewTableRowPinIconButton: 'loan-list-view-table-row-pin-icon-button',
    LoanProgressBar: 'loan-progress-bar',
    LoanListTableRowInfoButton: 'loan-list-table-row-info-button',
    FormElementApproveCheckbox: 'form-element-approve-checkbox',
    LoanListViewTableSortArrowDownwardIcon: 'ArrowDownwardIcon',
    LoanListViewPhaseCloseIconButton: 'loan-list-view-phase-close-icon-button',
    LoanOverviewHederContainerInfoButton: 'loan-overview-header-container-info-button',
    SpreadSheetViewerSkeleton: 'spread-sheet-viewer-skeleton',
    LoanKanbanPhaseLoadingMorePlaceholder: 'loan-kanban-phase-loading-more-placeholder',
} as const;
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import React from "react";
import { usePendingInvites } from "src/hooks/use-pending-invites";

import { useMaintenanceWindow } from "../../../hooks/use-maintenance-window";
import { useNotifications } from "./notifications.hook";
import { NotificationsStyles } from "./notifications.styles";
import { NotificationsPendingInvites } from "./notifications-pending-invites";

interface NotificationsButtonProps {
}

export const Notifications: React.FC<NotificationsButtonProps> = () => {

    const { message } = useMaintenanceWindow();
    const {
        onOpen,
        onClose,
        isOpen,
        anchorEl
    } = useNotifications()

    const { pendingInvites } = usePendingInvites();
    const hasNotifications = Boolean(pendingInvites.length) || !!message ? 1 : 0;

    return (
        <>
            <IconButton
                onClick={onOpen}
                color='default'>
                <Badge
                    overlap="circular"
                    color='error'
                    variant="dot"
                    badgeContent={hasNotifications}>
                    <NotificationsStyles.BellIcon
                        data-animated={hasNotifications}
                        color='white' />
                </Badge>
            </IconButton>
            <Popover
                anchorEl={anchorEl}
                onClose={onClose}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <NotificationsPendingInvites
                    onClose={onClose} />
            </Popover>
        </>
    );
};
import { useRouter } from 'next/router';
import React, { useCallback, useEffect } from 'react';
import { Route } from 'src/constants/ui';
import { useSubdomainRedirect } from 'src/hooks/use-subdomain-redirect';
import { ApiResponse } from 'src/types/api';

import { api } from "../api";
import {
    ChangePasswordFormValues,
    ConfirmForgotPasswordRequest,
    ConfirmSignupRequest,
    ForgotPasswordRequest,
    LoginRequest,
    NewPasswordRequest,
    SignUpRequest
} from "../types/auth";
import { User } from "../types/user";

interface AuthProviderProps {
    children: React.ReactNode;
}

interface State {
    changePasswordOpen: boolean;
    isInitialized: boolean;
    isAuthenticated: boolean;
    user: User | null;
}

interface AuthContextValue extends State {
    login: (info: LoginRequest) => Promise<any>;
    logout: () => Promise<any>;
    authenticate: () => Promise<any>;
    changePassword: (values: ChangePasswordFormValues) => Promise<ApiResponse>;
    newPassword: (info: NewPasswordRequest) => Promise<any>;
    signUp: (info: SignUpRequest) => Promise<any>;
    confirmSignUp: (info: ConfirmSignupRequest) => Promise<any>;
    forgotPassword: (info: ForgotPasswordRequest) => Promise<any>;
    confirmForgotPassword: (info: ConfirmForgotPasswordRequest) => Promise<any>;
}

const initialState: State = {
    changePasswordOpen: false,
    isInitialized: false,
    isAuthenticated: false,
    user: null,
}

export const AuthContext = React.createContext<AuthContextValue>({
    ...initialState,
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    authenticate: () => Promise.resolve(),
    newPassword: () => Promise.resolve(),
    signUp: () => Promise.resolve(),
    confirmSignUp: () => Promise.resolve(),
    forgotPassword: () => Promise.resolve(),
    confirmForgotPassword: () => Promise.resolve(),
    changePassword: (values: ChangePasswordFormValues) => Promise.resolve({} as ApiResponse),
});

export const AuthProvider: React.FC<AuthProviderProps> = (props) => {
    const [state, setState] = React.useState(initialState);
    // check if the app is running on a valid subdomain
    // if not, redirect to the landing page
    const { checkSubdomainValidity } = useSubdomainRedirect();
    const router = useRouter();
    const login = async (info: LoginRequest): Promise<any> => {
        let lResult = await api.login(info);

        if (lResult?.success === true) {
            // successful login
            setState(prevState => ({ ...prevState, isAuthenticated: true, isInitialized: true }))
        }

        return lResult;
    }

    const logout = async (): Promise<any> => {
        router.push({ pathname: Route.LOGOUT });
    }

    const authenticate = useCallback(async (): Promise<boolean> => {
        await checkSubdomainValidity()
        const lAuthentication = await api.authenticate();
        setState(prevState => ({
            ...prevState,
            isAuthenticated: lAuthentication.success,
            isInitialized: true
        }))
        return lAuthentication.success;
    }, [checkSubdomainValidity]);

    const newPassword = async (info: NewPasswordRequest): Promise<ApiResponse> => {
        return api.newPassword(info);
    }

    const signUp = async (info: SignUpRequest): Promise<ApiResponse> => {
        return api.signup(info);
    }

    const changePassword = (values: ChangePasswordFormValues): Promise<ApiResponse> => {
        return api.changePassword(values);
    }

    const confirmSignUp = async (info: ConfirmSignupRequest): Promise<ApiResponse> => {
        return api.confirmSignup(info);
    }

    const forgotPassword = async (info: ForgotPasswordRequest): Promise<ApiResponse> => {
        return api.forgotPassword(info);
    }

    const confirmForgotPassword = async (info: ConfirmForgotPasswordRequest): Promise<ApiResponse> => {
        return api.confirmForgotPassword(info);
    }

    useEffect(() => {
        authenticate();
    }, [authenticate]);

    return (
        <AuthContext.Provider
            value={{
                ...state,
                login,
                logout,
                authenticate,
                newPassword,
                signUp,
                changePassword,
                confirmSignUp,
                forgotPassword,
                confirmForgotPassword,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};

export const AuthConsumer = AuthContext.Consumer;

import { styled } from "@mui/material/styles"
import { themeV2 } from "src/theme/mysherpas-theme-option"

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    fontSize: theme.typography.pxToRem(12),
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2.25),
    color: themeV2.neutral.grey[100],

    '& .MuiButton-text': {
        color: themeV2.colors.blue[100],
    }
}))

export const NotificationsPendingInvitesHeaderStyles = {
    Container,

}
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Icon from 'components/dist/atoms/Icon';
import NextLink from 'next/link';
import PropTypes from 'prop-types';
import { FC, useContext, useState } from 'react';
import { LENDER_VIEW } from 'src/constants/person';
import { AuthContext } from 'src/contexts/auth-context';
import { SettingsContext } from 'src/contexts/settings-context';
import { useUser } from 'src/hooks/use-user';
import { availableViewSelector, setViewType, viewTypeSelector } from 'src/slices/view';
import { useDispatch, useSelector } from 'src/store';
import { intervalManager } from 'src/utils';
import { getInitials } from 'src/utils/get-initials';
import { toast } from 'src/utils/toast';
import { getUserDisplayName } from 'src/utils/user/get-user-display-name';

import { ViewType } from "../../backend";
import { SwitchHorizontalOutlined as SwitchHorizontalOutlinedIcon } from '../../icons/switch-horizontal-outlined';
import { UserAvatar } from '../user/user-avatar';
import { SupportMessagesModal } from './support-messages-modal';

interface AccountPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
}

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
  const { anchorEl, onClose, open = false, ...other } = props;
  const [isSupportChatOpen, setIsSupportChatOpen] = useState(false);
  const { settings } = useContext(SettingsContext);

  const { logout } = useContext(AuthContext);
  const dispatch = useDispatch();
  // redux state selectors
  const viewType = useSelector(viewTypeSelector);
  const { user, userRole } = useUser();
  const availableView = useSelector(availableViewSelector);
  // TODO update how we get user with multiple views
  const hasMultipleViews = false //availableView === LENDER_PRINCIPAL_VIEW;

  const handleSwitchAccount = (): void => {
    if (viewType === LENDER_VIEW) {
      dispatch(setViewType(ViewType.PRINCIPAL));
    } else {
      dispatch(setViewType(ViewType.LENDER));
    }
  };

  const handleToggleSupportChat = (): void => {
    setIsSupportChatOpen(prevState => !prevState);
  }

  const handleLogout = async () => {
    try {
      onClose?.();
      intervalManager.clearAll();
      await logout();
    } catch (err) {
      toast({
        content: 'Unable to logout'
      });
    }
  };

  const handleOpenSupportMessages = (): void => {
    setIsSupportChatOpen(true);
    onClose?.();
  }
  return (
    <>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        sx={{ zIndex: theme => theme.zIndex.modal + 6 }}
        keepMounted
        onClose={onClose}
        open={open}
        PaperProps={{ sx: { width: 300 } }}
        transitionDuration={0}
        {...other}
      >
        <Box
          sx={{
            alignItems: 'center',
            p: 2,
            display: 'flex'
          }}
        >
          <UserAvatar
            userId={user.id}
            role={userRole}
            sx={{
              height: 26,
              width: 26
            }}
          >
            {getInitials(getUserDisplayName(user))}
          </UserAvatar>
          <Box
            sx={{
              ml: 1
            }}
          >
            <Typography variant="body1">
              {getUserDisplayName(user)}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ my: 1 }}>
          {hasMultipleViews && <>
            <MenuItem onClick={handleSwitchAccount}>
              <ListItemIcon>
                <SwitchHorizontalOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={(
                  <Typography variant="body1">
                    Switch to {viewType === LENDER_VIEW ? 'Applicant' : 'Lender'}
                  </Typography>
                )}
              />
            </MenuItem>
            <Divider />
          </>}
          <NextLink href='/user/profile'>
            <MenuItem component='a' href='/user/profile'>
              <ListItemIcon>
                <Icon name="Settings" width={20} height={20} strokeWidth={2} className='text-black-primary' />
              </ListItemIcon>
              <ListItemText
                primary={(
                  <Typography variant="body1">
                    Settings
                  </Typography>
                )}
              />
            </MenuItem>
          </NextLink>
          <Divider />
          <MenuItem onClick={handleOpenSupportMessages}>
            <ListItemIcon>
              <Icon name="ChatLines" width={20} height={20} strokeWidth={2} className='text-black-primary' />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography variant="body1">
                  Contact support
                </Typography>
              )}
            />
          </MenuItem>
          <Divider />
          <NextLink href='/v2/learning-center'>
            <MenuItem
              onClick={onClose}
              component='a' href='/v2/learning-center'>
              <ListItemIcon>
                <Icon name="InfoEmpty" width={20} height={20} strokeWidth={2} className='text-black-primary' />
              </ListItemIcon>
              <ListItemText
                primary={(
                  <Typography variant="body1">
                    Learning center
                  </Typography>
                )}
              />
            </MenuItem>
          </NextLink>
          <Divider />
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Icon name="LogOut" width={20} height={20} strokeWidth={2} className='text-destructive' />
            </ListItemIcon>
            <ListItemText
              className='text-destructive'
              primary={(
                <Typography variant="body1">
                  Log out
                </Typography>
              )}
            />
          </MenuItem>
          <Divider />
          <ListItemText
            sx={{
              pointerEvents: 'none',
              '& .MuiTypography-root': {
                fontSize: 12
              },
              px: 2
            }}>
            {settings.version}
          </ListItemText>
        </Box>
      </Popover>
      <SupportMessagesModal
        isOpen={isSupportChatOpen}
        onClose={handleToggleSupportChat} />
    </>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

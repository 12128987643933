import LoadingButton from "@mui/lab/LoadingButton"
import MuiCard from "@mui/material/Card";
import MuiCardContent from "@mui/material/CardContent";
import MuiCardHeader from "@mui/material/CardHeader";
import MuiListItemText from "@mui/material/ListItemText";
import { keyframes, styled } from "@mui/material/styles";
import { Bell } from "src/icons/bell";
import { themeV2 } from "src/theme/mysherpas-theme-option"

const bellAnimation = keyframes`
    0%, 25%, 75%, 100% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(6deg);
    }
    45% {
      transform: rotate(-6deg);
    }
    55% {
      transform: rotate(3deg);
    }
    60% {
      transform: rotate(-3deg);
    }
  `;

const CardHeader = styled(MuiCardHeader)(({ theme }) => ({
    padding: theme.spacing(2.1, 2.25)
}))

const CardContent = styled(MuiCardContent)(({
    padding: 0,
    '&:last-child': {
        paddingBottom: 0
    }
}))

const BellIcon = styled(Bell)({
    color: themeV2.colors.blue[900],
    '&[data-animated="true"]': {
        transformOrigin: "top center",
        animation: `${bellAnimation} 2s infinite`
    },
})

const ListItemText = styled(MuiListItemText)(({ theme }) => ({
    '& .MuiListItemText-primary': {
        color: themeV2.neutral.grey[100],
        fontSize: theme.typography.pxToRem(14),
    },
    '& .MuiListItemText-primary span': {
        color: themeV2.colors.blue[900]
    },
    "&[data-last='true']": {
        paddingLeft: theme.spacing(2.25),
        textAlign: "right",
        flexShrink: 0,
        minWidth: 120,
        '& .MuiListItemText-primary': {
            fontSize: theme.typography.pxToRem(12),
        },
    }
}))

const Card = styled(MuiCard)(({ theme }) => ({
    minWidth: 504
}))

const ListItemButton = styled(LoadingButton)(({ theme }) => ({
    borderRadius: 6,
    padding: theme.spacing(1.25, 2.5),
    height: 28,
    minWidth: 100,
    fontSize: theme.typography.pxToRem(12),
    backgroundColor: themeV2.colors.blue[100],
}))

const EmptyNotifications = styled('div')(({ theme }) => ({
    padding: theme.spacing(2.5, 2.5),
}))

export const NotificationsStyles = {
    Card,
    EmptyNotifications,
    ListItemButton,
    BellIcon,
    ListItemText,
    CardHeader,
    CardContent
}

import { Autocomplete, Avatar, styled, TextField } from "@mui/material"
import { contextLoanSelector, selectLoanChatRecipients } from "src/slices/loan";
import { useSelector } from "src/store";
import { Loan } from "src/types/loan";
import { getInitials } from "src/utils/get-initials";

interface UserAutocompleteProps {
    defaultValue: string;
    placeholder?: string;
    loanId?: string;
};

interface IdAutoCompleteProps extends UserAutocompleteProps {
    onChange: (id: string) => void;
}

interface OptionAutoCompleteProps extends UserAutocompleteProps {
    onChangeUser?: (user: OptionUser) => void;
}
export interface OptionUser {
    id: string,
    fullName: string,
    group: string,
}

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.light,
    backgroundColor: 'white',
}));

const GroupItems = styled('ul')({
    padding: 0,
});

export const UserAutocomplete = (props: IdAutoCompleteProps | OptionAutoCompleteProps) => {
    const { defaultValue, placeholder } = props;

    const loan: Loan = useSelector(contextLoanSelector);

    const options: OptionUser[] = useSelector(selectLoanChatRecipients(loan?.id));

    const handleChange = (_: any, newValue: any | null) => {
        if (!!newValue) {
            if ('onChange' in props) {
                props.onChange(newValue.id);
            }
            // find user and fire onChangeUser
            const user = options.find(u => u.id === newValue.id);
            if (!!user && 'onChangeUser' in props) {
                props.onChangeUser(user);
            }
        }
    }
    return (<Autocomplete
        value={options.find(option => option.id === defaultValue) ?? null}
        id="user-autocomplete"
        options={options}
        size="small"
        onChange={handleChange}
        groupBy={(option) => option.group}
        getOptionLabel={(option) => option.fullName}
        sx={{ width: '100%', mb: 1 }}
        renderInput={(params) => <TextField {...params}
            label={placeholder ?? "To..."} />}
        renderGroup={(params) => (
            <li key={params.key}>
                <GroupHeader sx={{ backgroundColor: 'white', zIndex: 2 }}>{params.group}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
            </li>
        )}
        renderOption={(props, option) => (
            <li {...props} key={option.id}>
                <Avatar sx={{ width: 30, height: 30, mr: 1 }} key='avatar'>
                    {getInitials(option.fullName)}
                </Avatar>
                {option.fullName}
            </li>
        )}
    />)
}
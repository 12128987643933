import React from "react";
import { getKeyPreference, keyPreferenceSelector, saveKeyPreference } from "src/slices/ui";
import { useDispatch, useSelector } from "src/store";

interface ReturnValue<T> {
    value: T;
    isLoading: boolean;
    setValue: (value: T) => Promise<void>;
}

export function useKeyStorage<T>(key: string, defaultValue: any = null): ReturnValue<T> {
    const [isLoading, setIsLoading] = React.useState(true);
    const dispatch = useDispatch();
    const preference = useSelector(keyPreferenceSelector(key));

    React.useEffect(() => {
        if (!preference) {
            setIsLoading(true)
            dispatch(getKeyPreference(key))
                .catch(() => { })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    }, [dispatch, key, preference]);

    const handleSetValue = async (value: T) => {
        return dispatch(saveKeyPreference(key, value));
    };

    return {
        value: preference || defaultValue,
        isLoading,
        setValue: handleSetValue
    }
};

import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import NextLink from 'next/link'
import { Fragment } from 'react';

import { LoanSearchView } from './loan-search-view';

export const SearchResultRenderOption = (props, option, state,
    {
        query,
        onBlur,
        goToSearchPage,
        options
    }) => {
    const isLastItem = props['data-option-index'] >= options.length - 1;
    return (
        <Fragment key={props['data-option-index']}>
            <LoanSearchView item={option} props={props} query={state.inputValue} />
            <Divider />
            {isLastItem && goToSearchPage && <NextLink href={`/search?page=2&query=${query}`} passHref>
                <Link
                    onClick={onBlur}
                    sx={{ p: 2, display: 'flex' }}>
                    See more results
                </Link>
            </NextLink>}
        </Fragment>
    )
}
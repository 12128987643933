import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { formatDistance, parseISO } from "date-fns";
import { LinkActionInviteToLoan } from "src/backend";
import { UserAvatar } from "src/components/user/user-avatar";
import { RoleTypeLabel } from "src/constants/loan";
import { getInitials } from "src/utils/get-initials";
import { getUserDisplayName } from "src/utils/user/get-user-display-name";

import { NotificationsStyles } from "./notifications.styles";

interface NotificationsInviteListItemProps {
    invite: LinkActionInviteToLoan;
    onAccept: (invite: LinkActionInviteToLoan) => void;
    loading: boolean;
}

export const NotificationsInviteListItem = (props: NotificationsInviteListItemProps) => {

    const handleAcceptClick = () => {
        props.onAccept(props.invite);
    }

    return <>
        <ListItem>
            <ListItemAvatar>
                <UserAvatar
                    sx={{ width: 32, height: 32 }}
                    userId={props.invite.invitedBy.id}
                    role={props.invite.invitedBy.roleDefault}>
                    {getInitials(getUserDisplayName(props.invite.invitedBy))}
                </UserAvatar>
            </ListItemAvatar>
            <NotificationsStyles.ListItemText
                primary={<><span>{getUserDisplayName(props.invite.invitedBy)}</span> invited you to loan <span>{props.invite.loan.projectName}</span> as <span>{RoleTypeLabel(props.invite.userInvited.roleDefault)}</span></>}
            />
            <NotificationsStyles.ListItemText
                data-last={true}
                primary={formatDistance(parseISO(props.invite.createdDate), new Date(), { addSuffix: true })} />
        </ListItem>
        <ListItem divider>
            <ListItemText primary={<NotificationsStyles.ListItemButton
                loading={props.loading}
                onClick={handleAcceptClick}
                variant='contained'>
                Accept
            </NotificationsStyles.ListItemButton>} />
        </ListItem>
    </>;
};
import { useEffect, useState } from 'react';
import { api } from 'src/api';
import { NOT_SIGNED_IN_ERROR_MESSAGE } from 'src/constants/ui';
import { setUserSettingsFetched } from 'src/slices/ui';
import { setUserPreferences, updateUserPreferences } from 'src/slices/user';
import { useDispatch } from 'src/store';
import { UserPreference } from 'src/types/user';

export const useFetchUserPreferences = () => {
    const dispatch = useDispatch();
    const [preferences, setPreferences] = useState<UserPreference>(null);
    useEffect(() => {
        const getUserPreferences = async () => {
            try {
                const preferences = await api.getUserPreferences();
                setPreferences(preferences);
                dispatch(setUserPreferences(preferences))
            } catch (error) {
                // this happens when the user is anonymous
                if (error.message == NOT_SIGNED_IN_ERROR_MESSAGE) {
                    setPreferences({
                        agreedToTOS: true,
                    } as UserPreference);
                }
            } finally {
                dispatch(setUserSettingsFetched(true));
            }
        };
        if (!preferences) {
            getUserPreferences();
        }
    }, [dispatch, preferences]);

    const handleUpdateUserPreferences = async (
        updatedPreferences: Partial<UserPreference>
    ) => {
        dispatch(updateUserPreferences({
            ...updatedPreferences,
        }))
        setPreferences({
            ...preferences,
            ...updatedPreferences,
        });
    };

    return {
        loading: !preferences,
        ...preferences,
        onUpdate: handleUpdateUserPreferences,
    };
};

import Avatar, { type AvatarProps } from "@mui/material/Avatar"
import { forwardRef } from "react";
import { Role } from "src/backend";
import { RoleType } from "src/constants/loan";
import { UserType } from "src/constants/user"
import { useUserType } from "src/hooks/use-user-type";

interface Props extends React.PropsWithRef<AvatarProps> {
    userId: string;
    role?: Role
}

export const UserAvatar: React.FC<Props> = forwardRef((props, ref) => {
    const { userId, role, ...rest } = props;
    const userType = useUserType(userId);

    let bgcolor = "primary.main";
    if ((role === RoleType.BORROWER || role === RoleType.LeadBorrower) || (userType === UserType.APPLICANT && !role)) {
        bgcolor = "warning.main";
    } else if (userType === UserType.ANONYMOUS && !role) {
        bgcolor = "info.main";
    }
    return <Avatar
        {...rest}
        ref={ref}
        sx={{
            ...(props.sx || {}),
            bgcolor
        }} />
})

UserAvatar.displayName = "UserAvatar";
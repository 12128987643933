import { keyframes } from '@emotion/react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Head from 'next/head';
import Image from 'next/image';
import type { FC } from 'react';
import { getAssetPath } from 'src/utils/url/get-asset-path';

const bounce1 = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, 1px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
`;

const bounce3 = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, 3px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
`;

export const SplashScreen: FC = () => (
  <>
    <Head>
      <title>
        Loading...
      </title>
    </Head>
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: 'neutral.900',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        justifyContent: 'center',
        left: 0,
        p: 3,
        position: 'fixed',
        top: 0,
        width: '100vw',
        zIndex: 2000
      }}
    >
      <Box
        sx={{
          height: 36,
          width: 166,
          '& path:nth-of-type(1)': {
            animation: `${bounce1} 1s ease-in-out infinite`
          },
          '& path:nth-of-type(3)': {
            animation: `${bounce3} 1s ease-in-out infinite`
          }
        }}
      >
        <Image
          src={getAssetPath('static/232px_DarkMode.png')}
          alt="mysherpas-logo"
          width='166'
          height='36'
          unoptimized
        />
      </Box>
      <Box sx={{ width: 166, mt: 2 }}>
        <LinearProgress variant='indeterminate' />
      </Box>
    </Box>
  </>
);

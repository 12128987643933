import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useEffect, useRef } from 'react';
import ChatMessageAdd from 'src/components/loans/chat/chat-message-add';
import { SUPPORT_USERNAME } from 'src/constants/person';
import { sendMessage } from 'src/slices/chat';
import { userSelector } from 'src/slices/view';
import { useDispatch, useSelector } from 'src/store';
import { toast } from 'src/utils/toast';
import { getUserDisplayName } from 'src/utils/user/get-user-display-name';

export const SupportMessagesModal = ({ isOpen, onClose }) => (
    <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 800,
            backgroundColor: 'background.default',
            boxShadow: 24,
        }} position='absolute'>
            <FocusedChatMessageAdd onClose={onClose} />
        </Box>
    </Modal>
);

const FocusedChatMessageAdd = ({ onClose }: { onClose: () => void }) => {
    const chatReference = useRef(null);
    const dispatch = useDispatch();
    const user = useSelector(userSelector);

    const handleMessageSend = ({ subject, body, ids, replyToId }: { subject: string, body: string, ids: string[], replyToId?: string, receiverName?: string, toUserNames?: string[] }) => {
        dispatch(sendMessage({
            body: `${body}
            \r\n
            \r\n
            \r\n
            ${window.location.href}`,
            subject,
            name: user.name,
            username: getUserDisplayName(user),
            toUserIds: ids,
            toUserNames: [SUPPORT_USERNAME],
            receiverName: 'Support',
            receiverId: 'support',
            senderId: user.id,
            replyToId,
            contextId: null,
            readAt: null
        }));
        toast({
            type: 'success',
            content: 'Message sent to the support team.',
        });
        onClose();
    };

    useEffect(() => {
        if (chatReference.current) {
            chatReference.current.focus();
        }
    }, []);

    return (
        <ChatMessageAdd
            toUserNames={[SUPPORT_USERNAME]}
            defaultValues={{ subject: 'Support Message' }}
            selectedContacts={[]}
            roles={[]}
            onSend={handleMessageSend}
            ref={chatReference}
        />
    );
};

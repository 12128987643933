import { styled } from "@mui/material/styles"
import { themeV2 } from "src/theme/mysherpas-theme-option"

const Wrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(2, 2.5),
    backgroundColor: themeV2.colors.yellow[10],
    display: 'flex',
    gap: theme.spacing(2),
    maxWidth: 504
}))


const Row = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
}))

const Title = styled('div')(({ theme }) => ({
    color: 'black',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,

}))

const Content = styled('div')(({ theme }) => ({
    fontSize: theme.typography.pxToRem(14),
    color: themeV2.neutral.grey[200],
}))

const IconWrapper = styled('div')(({ theme }) => ({
    width: 32,
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: themeV2.colors.yellow[100],
    color: 'white',
    padding: theme.spacing(.5),

}))
export const NotificationMaintenanceListItemStyles = {
    Wrapper,
    Row,
    Title,
    Content,
    IconWrapper
}
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as React from 'react';
import { KeyboardKey } from 'src/constants/ui';
import { AuthContext } from 'src/contexts/auth-context';
import useEventListener from 'src/hooks/use-event-listener';
import { useFetchUserPreferences } from 'src/hooks/use-fetch-user-prefrences';
import { getAssetPath } from 'src/utils/url/get-asset-path';

import { SplashScreen } from './splash-screen';

type Props = {
  children?: JSX.Element;
}

export const PrivacyCookieConsent: React.FC<Props> = (props) => {
  const iframeReference = React.useRef<HTMLIFrameElement>();
  const contentDocumentReference = React.useRef<Document>();
  const { authenticate } = React.useContext(AuthContext);
  const [isAgreeEnabled, setAgreeEnable] = React.useState(false);
  const { loading, agreedToTOS, onUpdate } = useFetchUserPreferences();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const handleAgree = () => {
    if (isAgreeEnabled) {
      onUpdate({
        agreedToTOS: true
      });
    } else if (contentDocumentReference.current) {
      // get height of contentDocumentReference.current
      const height = contentDocumentReference.current.body.clientHeight;
      contentDocumentReference.current.body.scrollTop = contentDocumentReference.current.body.scrollTop + height;
    }
  };

  // detect user scroll all the way to bottom 
  const handleScroll = (event) => {
    const scrollTop = event.target.activeElement.scrollTop;
    const scrollHeight = event.target.activeElement.scrollHeight;
    const clientHeight = event.target.activeElement.clientHeight;
    // when browser is zoomed there is a 0~1px difference between scrollHeight and clientHeight + scrollTop
    // So to be safe we are adding 5px to the clientHeight + scrollTop and rounding to an integer
    if ((Math.round(scrollTop + clientHeight) + 5) >= scrollHeight) {
      setAgreeEnable(true);
    }
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === KeyboardKey.ENTER && !agreedToTOS) {
      event.stopPropagation();
      handleAgree();
    }
  }

  const handleLoad = (event) => {
    contentDocumentReference.current = event.target.contentDocument;
    contentDocumentReference.current?.addEventListener('scroll', handleScroll);
    contentDocumentReference.current?.addEventListener('keydown', handleKeyDown);
  }

  useEventListener('keydown', handleKeyDown);

  React.useEffect(() => {
    const ref = iframeReference.current;
    authenticate();
    if (ref) {
      return () => {
        ref?.removeEventListener('load', handleLoad);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    contentDocumentReference.current?.addEventListener('scroll', handleScroll);
    contentDocumentReference.current?.addEventListener('keydown', handleKeyDown);

    return () => {
      contentDocumentReference.current?.removeEventListener('scroll', handleScroll);
      contentDocumentReference.current?.removeEventListener('keydown', handleKeyDown);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAgreeEnabled, contentDocumentReference.current]);


  if (loading) {
    return <SplashScreen />
  }

  if (agreedToTOS) {
    return props.children;
  }

  return (<Dialog
    open={!agreedToTOS}
    closeAfterTransition={false}
    scroll='paper'
    fullWidth
    fullScreen={smDown}
    disableEscapeKeyDown
    maxWidth='lg'
    sx={{
      backgroundColor: `rgba(9, 14, 40, 0.3)`,
    }}
    aria-labelledby="scroll-dialog-title">
    <DialogTitle id="scroll-dialog-title">Platform License Agreement and Terms of Service</DialogTitle>
    <DialogContent dividers sx={{ p: 0, overflow: 'hidden' }}>
      <Box
        ref={(node: HTMLIFrameElement) => {
          if (!node) return;
          iframeReference.current = node;
          node.addEventListener('load', handleLoad);
        }}
        sx={{
          borderWidth: 0,
          px: {
            xs: 2,
            md: 3,
            lg: 4,
            xl: 5,
          },
          width: '100%',
          height: 800
        }}
        component='iframe' src={getAssetPath('static/pla.html')} />
    </DialogContent>
    <DialogActions>
      {!isAgreeEnabled && <Typography color='primary.main' fontWeight='bold' flex='1'>
        Read all the way to the bottom of this page to agree to our Platform License Agreement and Terms of Service.
      </Typography>}
      <Button
        data-testid='agree-button'
        variant='contained'
        onClick={handleAgree}>
        {isAgreeEnabled ? 'I Agree' : 'Next Page...'}
      </Button>
    </DialogActions>
  </Dialog>
  );
}


// a function to get the url without the protocol and port
// eg https://www.sherpas.ai:8080/ -> www.sherpas.ai
export const getUrlWithoutProtocolAndPort = (url: string) => {
    // handle case where url is null
    if (!url) {
        return null;
    }
    let result = url;
    // if we have a url with a protocol, remove it
    if (url.includes('//')) {
        result = url.split('//')[1];
    }
    // if we have a url with a port, remove it
    if (url.includes(':')) {
        result = result.split(':')[0];
    }
    return result;
}
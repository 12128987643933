import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { PrivacyCookieConsent } from 'src/components/privacy-cookie-consent';
import { GuestRoutes, Route } from 'src/constants/ui';
import { useAuth } from 'src/hooks/use-auth';

const IdleNotification = dynamic(() => import('src/components/IdleNotification'));

interface AuthGuardProps {
    children: JSX.Element;
}

export const AuthGuard: FC<AuthGuardProps> = (props) => {
    const { children } = props;
    const auth = useAuth();
    const { query, isReady, asPath, push } = useRouter();
    const [checked, setChecked] = useState(false);

    useEffect(
        () => {
            if (!isReady || !auth.isInitialized) {
                return;
            }
            const asPathWithoutParams = String(asPath).split('?')[0];

            if (!auth.isAuthenticated && !(GuestRoutes as string[]).includes(asPathWithoutParams)) {
                push({
                    pathname: Route.LOGIN,
                    query: { ...query, returnUrl: encodeURIComponent(asPath) }
                });
            } else if ((GuestRoutes as string[]).includes(asPathWithoutParams)) {
                push({
                    pathname: asPathWithoutParams,
                    query
                });
            } else {
                setChecked(true);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isReady, auth.isInitialized, auth.isAuthenticated, push, asPath, query]
    );

    if (!checked) {
        return null;
    }
    // If got here, it means that the redirect did not occur, and that tells us that the user is
    // authenticated / authorized.

    return <PrivacyCookieConsent>
        <>
            {children}
             {/* @ts-expect-error */}
            <IdleNotification />
        </>
    </PrivacyCookieConsent>;
};

AuthGuard.propTypes = {
    children: PropTypes.element
};

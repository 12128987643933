import { useState } from 'react';
import { api } from 'src/api';
import { sendMessage, setReplyToMessage } from 'src/slices/chat';
import { userSelector } from 'src/slices/view';
import { useDispatch, useSelector } from 'src/store';
import { Message } from 'src/types/chat';
import { toast } from 'src/utils/toast';

type Props = {
    loanId: string;
    userId: string;
}

export const useSendDigest = (props: Props) => {
    const dispatch = useDispatch();
    const { loanId, userId } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const loggedInUser = useSelector(userSelector);
    const composeDigest = async () => {
        setIsLoading(true);
        setError(null);

        try {
            const html = await api.getTasksAsHtml({ loanId, userId });
            dispatch(setReplyToMessage({
                id: null,
                subject: `Needs List`,
                body: html,
                senderId: userId,
                context: 'LOAN',
                contextId: loanId,
            } as Message))
        }
        catch (error) {
            setError(error.message);
        }

        setIsLoading(false);

        return error;
    }

    const sendDigest = async () => {
        dispatch(sendMessage({
            subject: '',
            body: 'should not show',
            messageType: 'DIGEST',
            toUserIds: [userId],
            contextId: loanId,
            senderId: loggedInUser.id,
            receiverName: '',
            receiverId: userId,
        }));
        toast({
            content: `Digest sent`,
            type: 'success',
        })
    }

    return {
        composeDigest,
        sendDigest,
        isLoading,
        error
    }
}
import { IUser, LoanRoleDto, Role } from "src/backend";
import { userSelector } from "src/slices/view";
import { useSelector } from "src/store";
import { Loan } from "src/types/loan";
import { isRoleABorrower } from "src/utils/user/is-role-a-borrower";

interface UserProps {
    loan?: Loan;
}

interface ReturnValue {
    user: IUser;
    userRole?: Role
    loanRole?: LoanRoleDto
    isBorrower: boolean
    isLender: boolean
    isUnderwriter: boolean
}

export const useUser = (props: UserProps = {}): ReturnValue => {
    const user = useSelector(userSelector);

    const loanRole = props.loan?.loanRoles.find((role) => role.user.id === user.id);

    let userRole = null;

    if (typeof loanRole !== 'undefined') {
        userRole = loanRole.role;
    } else if (user) {
        userRole = user.roleDefault;
    }
    return {
        user,
        loanRole,
        userRole,
        isBorrower: isRoleABorrower(userRole),
        isLender: !isRoleABorrower(userRole),
        isUnderwriter: userRole === Role.UNDERWRITER_LENDER,
    }
};
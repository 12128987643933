import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import NextLink from 'next/link'

const highlightStyle = {
    fontWeight: 700,
    backgroundColor: "warning.light",
};

export const LoanSearchView = ({ item, props = {}, query }: { item: any, props?: any, query?: string }) => {
    const headerMatches = match(item.header, String(query));
    const headerParts = parse(item.header, headerMatches);

    const hiTextMatches = match(item.hitText, String(query));
    const hitTextParts = parse(item.hitText, hiTextMatches);


    return (<Box component="li" {...props} sx={{ display: 'flex' }}>
        <NextLink href={item.link} passHref>
            <Link sx={{
                width: '100%', '&:hover': {
                    textDecoration: 'none'
                }
            }}>
                <Typography sx={{ fontWeight: 'bold', width: '100%', textAlign: 'left' }}>
                    {headerParts.map((part, index: number) => (
                        <Box component='span' key={index} sx={part.highlight ? highlightStyle : {}}>
                            {part.text}
                        </Box>
                    ))}
                </Typography>
                <Typography variant='body1' sx={{ width: '100%', color: 'black' }}>
                    {hitTextParts.map((part, index: number) => (
                        <Box component='span' key={index} sx={part.highlight ? highlightStyle : {}}>
                            {part.text}
                        </Box>
                    ))}
                </Typography>

            </Link>
        </NextLink>
    </Box>)
}